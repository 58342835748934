<template>
  <div id="changepassword">
    <div class="titleLine">
      <h1>Please Verify Your Email</h1>
      <h3>
        設定您賬戶的基本資料及其他基礎信息設定您賬戶的基本資料及其他基礎信息設定您賬戶的
        基本資料及其他基礎信息
      </h3>
    </div>
    <div class="content">
      <div class="contentItem1" v-if="!isPwdOK">
        <email-check-item color="#fff" v-model="email" v-on:emailOk="checkEmailOk"></email-check-item>
        <btn-submit class="btnSend" @click="sendCode">{{getCode}}</btn-submit>
      </div>
      <div class="contentItem" v-if="isPwdOK">
        <div class="inputBox">
          <label class="title"><h2>Enter Your New Password:</h2></label>
          <input type="password" placeholder="Enter Your New Password">
        </div>
        <div class="inputBox">
          <label class="title"><h2>Enter Your New Password Again:</h2></label>
          <input type="password" placeholder="Enter Your New Password">
        </div>
      </div>
    </div>
    <btn-submit color="red" class="btn" @click="goNext">{{btnCentent}}</btn-submit>
  </div>
</template>

<script>
import { sendCodeMail } from '@/axios/api/login'
import BtnSubmit from '@/components/button/BtnSubmit.vue'
import EmailCheckItem from '@/components/form/EmailCheckItem.vue'
export default {
  name:'ChangePassword',
  components: {
    BtnSubmit,
    EmailCheckItem
  },
  data() {
    return {
      isPwdOK: false,
      btnCentent: "Next",
      count:60,
      email:"",
      code:'',
      getCode:'Get Code',
      emailOK:false,
    }
  },
  methods:{
    goNext(){
      this.isPwdOK = !this.isPwdOK
      this.count++
      if(this.isPwdOK){
        this.btnCentent = "Save"
      }else{
        this.btnCentent = "Next"
      }
      if(this.count==3){
        this.$router.push("/usercenter")
      }
    },
    sendCode(){
      if(this.emailOK){
        this.disable = true;
        sendCodeMail(this.sendCodeForm)
        var countDown = setInterval(()=>{
          if(this.count < 1){
            this.disable = false;
            this.isGeting = false;
            this.getCode = 'Send';
            this.count = 60;
            clearInterval(countDown);
          }else{
            this.disable = true;
            this.isGeting = true;
            this.getCode = this.count-- + 's Resend';
          }
        },1000);
      } else {
        alert("Please Enter Email First")
      }
    },
    checkEmailOk(){
      this.emailOK=true
    },
  },
}
</script>

<style scoped>
.titleLine{
  color: aliceblue;
  height: 140px;
  width: 50%;
  padding-top: 40px;
}
h3{
  color: rgb(110, 110, 110);
}
h2{
  color: aliceblue;
}
.content{
  display: flex;
}
.contentItem{
  width: 400px;
}
input{
  border: none;
  outline: none;
  background: none;
  border-bottom: 1px solid #fff;
  width: 340px;
  height: 40px;
  font-size: 18px;
  color: #fff;
}
.inputBox{
  margin-bottom: 20px;
}
.btn{
  margin-left: 40%;
  margin-top: 100px;
}
.contentItem1{
  display: flex;
}
.btnSend{
  margin-top: 30px;
  margin-left: 40px;
}
</style>
